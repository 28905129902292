import ReactGA from "react-ga"

export const initializeGoogleAnalytics = () => {
    ReactGA.initialize("UA-113594960-10");
};

export const registerPageView = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname)
};
